import { CallToAction, TrackedCTA } from 'components/CallToAction'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import { prepareData } from 'utils/googleData'

export default function Hero(props: {
    title: string
    subtitle: string
    heroDescription: string
    image: {
        image: ImageDataLike
        width: number | string
        height: number | string
    }
    svgImage: string
    video?: string
    cta: {
        title: string
        url: string
    }
    cta2: {
        title: string
        url: string
    }
}) {
    const { enable, title, subtitle, description, cta, cta2, svgImage, gatsbyImage, imageStyles, video } = prepareData({
        props,
    })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <>
            <div>
                <h1
                    id="overview"
                    className="mb-0 mt-8 text-center text-3xl dark:rounded dark:bg-primary dark:bg-opacity-50 dark:p-4 md:mt-14 lg:text-6xl 2xl:text-7xl"
                >
                    <span className="text-red">{title}</span>
                </h1>
                <p className="mt-4 text-center text-lg font-semibold" dangerouslySetInnerHTML={{ __html: subtitle }} />
                {cta && (
                    <div className="flex flex-col justify-center space-y-3 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                        <TrackedCTA to={cta.url} size="sm" className="md:min-w-[200px]" event={{ name: cta?.event }}>
                            {cta.title}
                        </TrackedCTA>
                        {cta2 && (
                            <CallToAction type="secondary" to={cta2.url} size="sm" className="md:min-w-[200px]">
                                {cta2.title}
                            </CallToAction>
                        )}
                    </div>
                )}
            </div>
            {gatsbyImage && (
                <div style={imageStyles} className="leading-0 relative mx-auto mt-8 px-4 text-center md:-mb-12">
                    <GatsbyImage alt={title} image={gatsbyImage} className="rounded-md" />
                </div>
            )}
            {svgImage && !gatsbyImage && (
                <div style={imageStyles} className="leading-0 relative mx-auto mt-8 px-4 text-center md:-mb-12">
                    <img alt={title} className="w-full rounded-md" src={svgImage} />
                </div>
            )}
        </>
    )
}
